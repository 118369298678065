import React from "react"
import ModalContainer from "./ModalContainer"
import axios from "axios"
import moment from "moment"
import { Modal, BookingStepOne, BookingStepTwo } from "../components"
import "react-day-picker/lib/style.css"

const initialFormValues = {
  startDate: null,
  endDate: null,
  email: "",
  phone: "",
  guests: "1",
  rooms: "1",
  comments: "",
  name: "",
}

export const Booking = () => {
  const [showModal, setShowModal] = React.useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = React.useState(
    false
  )

  const [formValues, setFormValues] = React.useState(initialFormValues)

  const { startDate, endDate } = formValues

  const handleSubmit = () => {
    setShowModal(true)
  }

  const handleBooking = () => {
    axios
      .post("/.netlify/functions/book", {
        ...formValues,
        startDate: moment(startDate).format("DD/MM/YYYY"),
        endDate: moment(endDate).format("DD/MM/YYYY"),
      })
      .then(() => {
        setShowConfirmationModal(true)
      })
      .catch((e) => e)
      .finally(() => {
        setFormValues(initialFormValues)
        setShowModal(false)
      })
  }

  return (
    <>
      {showModal && (
        <ModalContainer setShowModal={setShowModal}>
          <Modal
            showModal={showModal}
            setShowModal={setShowModal}
            secondaryButton={{
              text: "Cancelar",
              action: () => setShowModal(false),
            }}
            primaryButton={{ text: "Confirmar", action: handleBooking }}
          >
            <BookingStepTwo
              formValues={formValues}
              setFormValues={setFormValues}
            />
          </Modal>
        </ModalContainer>
      )}
      {showConfirmationModal && (
        <ModalContainer setShowModal={setShowConfirmationModal}>
          <Modal
            showModal={showConfirmationModal}
            setShowModal={setShowConfirmationModal}
            primaryButton={{
              text: "Ok",
              action: () => setShowConfirmationModal(false),
            }}
          >
            Sus datos han sido enviados, nos contactaremos con usted a la
            brevedad
          </Modal>
        </ModalContainer>
      )}
      <BookingStepOne
        onSubmit={handleSubmit}
        formValues={formValues}
        setFormValues={setFormValues}
      />
    </>
  )
}
