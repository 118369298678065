import React from "react"
import entradaHotel from "../images/entrada-hotel.jpg"

function yearsDiff(date1, date2) {
  return date2.getFullYear() - date1.getFullYear()
}

export const AboutUs = () => (
  <section style={{ background: "#fafafa" }}>
    <div className="max-w-5xl px-6 py-24 mx-auto text-center">
      <h2 className="text-3xl font-semibold text-gray-800">
        Quienes
        <br />
        somos
      </h2>
      <p className="max-w-lg mx-auto mt-4 text-gray-600">
        Somos una empresa hotelera enclavada desde hace más de{" "}
        {yearsDiff(new Date("1978-05-26"), new Date())} años en plena mesa de
        Guanipa, emporio petrolero y agrícola de gran importancia para la
        economía del país. Nuestro hotel cuenta con 240 habitaciones equipadas
        con lo necesario para que su estadía con nosotros sea lo mas agradable
        posible.
      </p>
      <img
        className="object-cover object-center w-full mt-16 mb-16 rounded-md shadow h-80"
        src={entradaHotel}
      />
      <p className="max-w-lg mx-auto mt-4 text-gray-600">
        Internacional Gran Hotel se encuentra ubicado a 15 minutos del
        aeropuerto de San Tomé y a pocos minutos de las principales
        instalaciones petroleras de la zona. Así, con nuestras 3 estrellas, nos
        sentimos orgullosos de prestar el mejor servicio y a precios muy
        accesibles, prometiendole que haremos lo imposible para que en su visita
        a la zona sur del estado Anzoátegui, se sienta como en su propia casa.
      </p>
    </div>
  </section>
)

/*
<section className="bg-white">
        <div className="max-w-5xl px-6 py-16 mx-auto space-y-8 md:flex md:items-center md:space-y-0">
            <div className="md:w-2/3">
                <div className="hidden md:flex md:items-center md:space-x-10">
                    <img className="object-cover object-center rounded-md shadow w-72 h-72" src="https://images.unsplash.com/photo-1614030126544-b79b92e29e98?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;ixlib=rb-1.2.1&amp;auto=format&amp;fit=crop&amp;w=634&amp;q=80" />
                    <img className="object-cover object-center w-64 rounded-md shadow h-96" src="https://images.unsplash.com/photo-1618506469810-282bef2b30b3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;ixlib=rb-1.2.1&amp;auto=format&amp;fit=crop&amp;w=1350&amp;q=80" />
                </div>
                <h2 className="text-3xl font-semibold text-gray-800 md:mt-6">Lorem ipsum dolor </h2>
                <p className="max-w-lg mt-4 text-gray-600">
                    Duis aute irure dolor in reprehenderit in voluptate velit esse illum
                    dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
                    officia
                    deserunt mollit anim id est laborum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                    non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                </p>
            </div>
            <div className="md:w-1/3">
                <img className="object-cover object-center w-full rounded-md shadow" style="height: 700px;" src="https://images.unsplash.com/photo-1593352216840-1aee13f45818?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;ixlib=rb-1.2.1&amp;auto=format&amp;fit=crop&amp;w=634&amp;q=80">
            </div>
        </div>
    </section>*/
