import React from "react"
import { Link } from "gatsby"
import { FaPhone } from "react-icons/fa"

export const Navigation = () => {
  const [showMenu, setShowMenu] = React.useState(false)

  const MenuOptions = () => (
    <>
      <Link
        to="/"
        className="text-gray-500 hover:bg-gray-800 hover:text-white px-3 py-2 rounded-md text-4xl md:text-sm font-medium"
      >
        Inicio
      </Link>

      <Link
        to="/about-us"
        className="text-gray-500 hover:bg-gray-800 hover:text-white px-3 py-2 rounded-md text-4xl md:text-sm font-medium"
      >
        Quienes somos
      </Link>

      <Link
        to="/restaurants"
        className="text-gray-500 hover:bg-gray-800 hover:text-white px-3 py-2 rounded-md text-4xl md:text-sm font-medium"
      >
        Restaurantes
      </Link>

      <Link
        to="/contact"
        className="text-gray-500 hover:bg-gray-800 hover:text-white px-3 py-2 rounded-md text-4xl md:text-sm font-medium"
      >
        Contacto
      </Link>
    </>
  )

  return (
    <nav className="bg-white">
      <div className="p-6 max-w-7xl mx-auto px-6 sm:px-6 lg:px-8">
        <div className="relative flex items-center justify-between h-16">
          <div className="w-full absolute inset-y-0 flex items-center md:hidden justify-end">
            <button
              type="button"
              className="inline-flex items-center p-2 rounded-md text-white bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              aria-controls="mobile-menu"
              aria-expanded="false"
              onClick={() => setShowMenu(!showMenu)}
            >
              <span className="sr-only">Abrir menu principal</span>
              <svg
                className="block h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>

              <svg
                className="hidden h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div className="flex-shrink-0 flex items-center justify-self-start">
            <Link to="/">
              <img
                className="block lg:hidden h-8 w-auto"
                src="logo.svg"
                alt="Internacional Gran Hotel"
              />
              <img
                className="hidden lg:block h-8 w-auto"
                src="logo.svg"
                alt="Internacional Gran Hotel"
              />
            </Link>
            <a href="tel:+582832411732">
              <span className="mx-3 px-2 py-1 text-gray-800 rounded bg-gray-100">
                <FaPhone
                  style={{
                    display: "inline",
                    paddingRight: "10px",
                    height: "24px",
                    width: "24px",
                  }}
                />
                +58 283 2411732
              </span>
            </a>
          </div>
          <div className="flex items-center justify-self-start">
            <div className="hidden md:flex md:ml-6 justify-end">
              <div className="flex space-x-4">
                <MenuOptions />
              </div>
            </div>
          </div>
        </div>
      </div>
      {showMenu && (
        <div
          className="absolute w-full flex flex-col justify-around bg-white text-center md:hidden"
          style={{ zIndex: 1000, height: "calc(100vh - 112px)" }}
        >
          <MenuOptions />
        </div>
      )}
    </nav>
  )
}
