import React from "react"
import { Navigation } from "."

export const InnerTop = () => {
  return (
    <main
      className="flex items-center text-white relative"
      style={{ background: "#fafafa", height: "112px" }}
    >
      <header
        className="w-full absolute left-0 top-0 lg:pt-0"
        style={{ background: "#fff" }}
      >
        <Navigation />
      </header>
    </main>
  )
}
