import React from "react"
import axios from "axios"
import { Link } from "gatsby"
import { FaEnvelope, FaUser, FaPhone } from "react-icons/fa"
import ModalContainer from "./ModalContainer"
import { Modal } from "./"

const initialFormValues = {
  email: "",
  phone: "",
  comments: "",
  name: "",
}

export const Contact = () => {
  const [formValues, setFormValues] = React.useState("")
  const [showConfirmationModal, setShowConfirmationModal] = React.useState(
    false
  )

  const { email, name, phone, comments } = formValues
  const handleSubmit = (e) => {
    e.preventDefault()
    axios
      .post("/.netlify/functions/contact", {
        ...formValues,
      })
      .then(() => {
        setShowConfirmationModal(true)
      })
      .catch((e) => e)
      .finally(() => {
        setFormValues(initialFormValues)
      })
  }
  return (
    <section style={{ background: "#fafafa" }}>
      {showConfirmationModal && (
        <ModalContainer setShowModal={setShowConfirmationModal}>
          <Modal
            showModal={showConfirmationModal}
            setShowModal={setShowConfirmationModal}
            primaryButton={{
              text: "Ok",
              action: () => setShowConfirmationModal(false),
            }}
          >
            Sus datos han sido enviados, nos contactaremos con usted a la
            brevedad
          </Modal>
        </ModalContainer>
      )}
      <div className="max-w-3xl px-6 py-24 mx-auto text-center">
        <h2 className="text-3xl font-semibold text-gray-800">Contacto</h2>
        <p className="max-w-lg mx-auto mt-4 text-gray-600">
          Puedes contactarnos a través del formulario y nos comunicaremos a la
          brevedad
        </p>
        <form className="p-2 lg:p-6 mb-4">
          <div className="pb-6">
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 pl-8 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="name"
              type="text"
              value={name}
              onChange={(e) =>
                setFormValues({ ...formValues, name: e.target.value })
              }
              placeholder="Nombre"
            />
            <FaUser className="-mt-7 ml-2 text-gray-300" />
          </div>
          <div className="pb-6">
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 pl-8 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="email"
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) =>
                setFormValues({ ...formValues, email: e.target.value })
              }
            />
            <FaEnvelope className="-mt-7 ml-2 text-gray-300" />
          </div>
          <div className="pb-6">
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 pl-8 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="telephone"
              type="tel"
              value={phone}
              onChange={(e) =>
                setFormValues({ ...formValues, phone: e.target.value })
              }
              placeholder="Teléfono"
            />
            <FaPhone className="-mt-7 ml-2 text-gray-300" />
          </div>
          <div className="pb-6">
            <textarea
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-32"
              id="comments"
              type="text"
              value={comments}
              onChange={(e) =>
                setFormValues({ ...formValues, comments: e.target.value })
              }
              placeholder="Escriba su mensaje"
            />
          </div>
          <button
            onClick={handleSubmit}
            className="w-full bg-primary hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
          >
            Enviar
          </button>
        </form>
        <div className="whatsapp-inline" style={{ color: "black" }}>
          <div className="whatsapp-inline__big">
            <a href="https://wa.me/584121377062">Contáctanos por WhatsApp</a>
          </div>
          <div className="whatsapp-inline__small">
            para una atención inmediata
          </div>
        </div>
        <hr className="mt-12 mb-12" />
        <h2 className="text-2xl font-semibold text-gray-800">Dirección</h2>
        <p className="max-w-lg mx-auto mt-4 text-gray-600">
          Avenida Intercomunal – El Tigre. Estado Anzoategui. Venezuela.
        </p>
        <p className="max-w-lg mx-auto mt-4 text-gray-600">
          Apartado Postal 626.
        </p>
        <hr className="mt-12 mb-12" />
        <h2 className="text-2xl font-semibold text-gray-800">Teléfonos</h2>
        <p className="max-w-lg mx-auto mt-4 text-gray-600">
          También puedes contactarnos a través de los siguientes números
          telefónicos
        </p>
        <p className="max-w-lg mx-auto mt-4 text-gray-600">
          <a href="tel:+582832411732">+58-283-2411732</a>
          <br />
          <a href="tel:+582832410413">+58-283-2410413</a>
          <br />
          <a href="tel:+582832410672">+58-283-2410672</a>
          <br />
          <a href="tel:+582832410366">+58-283-2410366</a>
          <br />
          <a href="tel:+582832410883">+58-283-2410883</a>
          <br />
          <a href="tel:+582832410711">+58-283-2410711</a>
        </p>
      </div>
    </section>
  )
}
