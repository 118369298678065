import React from "react"
import { Navigation, Booking } from "./"

function yearsDiff(date1, date2) {
  return date2.getFullYear() - date1.getFullYear()
}

export const FullHero = () => {
  return (
    <main className="full-hero flex items-center entrada-image-background bg-primary text-white relative">
      <div
        className="absolute h-full w-full"
        style={{
          background:
            "linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.9) 36%, rgba(255,255,255,0.3) 100%)",
        }}
      ></div>
      <header className="w-full absolute left-0 top-0 lg:pt-0">
        <Navigation />
      </header>
      <section className="absolute top-24 md:bottom-auto w-full p-6 lg:p-32 text-black flex flex-col lg:flex-row justify-between">
        <div className="p-6 lg:w-1/2 xl:w-1/3">
          <h1 className="text-3xl lg:text-6xl font-bold text-red drop-shadow">
            Internacional
            <br />
            Gran
            <br />
            Hotel
          </h1>
          <p className="font-bold text-sm lg:text-2xl pt-4 text-gray-800">
            Más de {yearsDiff(new Date("1978-05-26"), new Date())} años
          </p>
          <p className="font-bold mb-2 text-sm lg:text-2xl text-gray-800">
            ofreciendo el mejor servicio
            <br />
            en El Tigre, Estado Anzoátegui
          </p>
        </div>
        <div className="p-2 lg:p-6 lg:w-1/2 xl:w-1/3 bg-black bg-opacity-40 text-white rounded rounded-md">
          <h1 className="text2xl lg:text-4xl font-bold p-2 lg:p-6 lg:pb-1">
            ¡Reserva ahora!
          </h1>
          <p className="p-2 lg:p-6 lg:pt-0 pt-0 pb-0 lg:pb-0 text-sm lg:text-lg">
            Con la mejor atención personalizada y sin tarjeta de crédito
          </p>
          <Booking />
          <div className="whatsapp-inline">
            <div className="whatsapp-inline__big">
              <a href="https://wa.me/584121377062">Contáctanos por WhatsApp</a>
            </div>
            <div className="whatsapp-inline__small">para más información</div>
          </div>
        </div>
      </section>
    </main>
  )
}
