import React from "react"
import { Link } from "gatsby"
import { FaCamera } from "react-icons/fa"
import ModalContainer from "./ModalContainer"
import { Modal } from "../components"
import conference from "../images/svg/conference.svg"
import party from "../images/svg/party.svg"
import drinks from "../images/svg/drinks.svg"
import food from "../images/svg/food.svg"
import hair from "../images/svg/hair.svg"
import pool from "../images/svg/pool.svg"
import partyRoom from "../images/party-room.jpeg"
import conferenceRoom from "../images/conference-room.jpeg"

export const Services = () => {
  const [modalProps, setModalProps] = React.useState({ showModal: false })
  const { showModal, contentKey } = modalProps

  return (
    <section className="mt-24" style={{ background: "#fafafa" }}>
      {showModal && (
        <ModalContainer
          setShowModal={(showModal) => setModalProps({ showModal })}
        >
          <Modal
            showModal={showModal}
            setShowModal={(showModal) => setModalProps({ showModal })}
          >
            {contentKey && contentKey === "party" && (
              <>
                <h4 className="text-xl font-bold leading-tight mb-4">
                  Salon de fiestas
                </h4>
                <p className="mb-4">
                  Capacidad para 500 personas, comunicate por{" "}
                  <Link className="text-blue-400" href="tel:+582832411732">
                    teléfono
                  </Link>{" "}
                  o{" "}
                  <Link className="text-blue-400" to="contact">
                    contáctanos
                  </Link>{" "}
                  a través del formulario, para reservar la sala
                </p>
                <img src={partyRoom} />
              </>
            )}
            {contentKey && contentKey === "conference" && (
              <>
                <h4 className="text-xl font-bold leading-tight mb-4">
                  Salon de conferencias
                </h4>
                <p className="mb-4">
                  Capacidad para 60 personas, comunicate por{" "}
                  <Link className="text-blue-400" href="tel:+582832411732">
                    teléfono
                  </Link>{" "}
                  o{" "}
                  <Link className="text-blue-400" to="contact">
                    contáctanos
                  </Link>{" "}
                  a través del formulario, para reservar la sala
                </p>
                <img src={conferenceRoom} />
              </>
            )}
          </Modal>
        </ModalContainer>
      )}
      <div className="max-w-5xl px-6 py-24 mx-auto text-center">
        <h2 className="text-3xl font-semibold text-gray-800">Servicios</h2>
        <div className="flex flex-col md:flex-row items-start justify-between mt-12">
          <div className="w-full bg-white shadow-lg rounded-lg px-4 py-6 lg:p-8 md:mx-2 lg:mx-4">
            <img src={food} alt="" className="mx-auto h-32" />
            <h4 className="text-xl font-bold leading-tight mt-8">
              Comida Internacional
            </h4>
            <p className="text-gray-700 mt-2 h-24">
              En nuestro restaurante o directamente en su habitación, los
              mejores platos hechos por un excelente chef local.
            </p>
          </div>

          <div className="w-full bg-white shadow-lg rounded-lg px-4 py-6 lg:p-8 md:mx-2 lg:mx-4 mt-4 md:mt-0">
            <img src={pool} alt="" className="mx-auto h-32" />
            <h4 className="text-xl font-bold leading-tight mt-8">
              Piscina y Gimnasio
            </h4>
            <p className="text-gray-700 mt-2 h-24">
              Acceso gratuito al área de la piscina y el gimnasio.
            </p>
          </div>

          <div className="w-full bg-white shadow-lg rounded-lg px-4 py-6 lg:p-8 md:mx-2 lg:mx-4 mt-4 md:mt-0">
            <img src={drinks} alt="" className="mx-auto h-32" />
            <h4 className="text-xl font-bold leading-tight mt-8">
              Bebidas y tragos
            </h4>
            <p className="text-gray-700 mt-2 h-24">
              En su habitación, de nuestra selecta reserva de bebidas nacionales
              e internacionales.
            </p>
          </div>
        </div>
        <div className="flex flex-col md:flex-row items-start justify-between mt-12">
          <div className="w-full bg-white shadow-lg rounded-lg px-4 py-6 lg:p-8 md:mx-2 lg:mx-4">
            <img src={hair} alt="" className="mx-auto h-32" />
            <h4 className="text-xl font-bold leading-tight mt-8">Peluquería</h4>
            <p className="text-gray-700 mt-2 h-24">
              Servicio de peluquería y barbería dentro de las instalaciones del
              hotel.
            </p>
          </div>

          <div className="w-full bg-white shadow-lg rounded-lg px-4 py-6 lg:p-8 md:mx-2 lg:mx-4 mt-4 md:mt-0">
            <img src={party} alt="" className="mx-auto h-32" />
            <h4 className="text-xl font-bold leading-tight mt-8">
              Salon de fiestas
            </h4>
            <p className="text-gray-700 mt-2 h-24">
              Para que tu evento salga como en las películas
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault()
                  setModalProps({ showModal: true, contentKey: "party" })
                }}
                className="text-center mt-4 mx-auto h-8 w-32 py-2 px-4 bg-gray-100 text-gray-800 flex justify-between items-center rounded"
              >
                <FaCamera className="inline" /> Ver más
              </a>
            </p>
          </div>

          <div className="w-full bg-white shadow-lg rounded-lg px-4 py-6 lg:p-8 md:mx-2 lg:mx-4 mt-4 md:mt-0">
            <img src={conference} alt="" className="mx-auto h-32" />
            <h4 className="text-xl font-bold leading-tight mt-8">
              Sala de Conferencias
            </h4>
            <p className="text-gray-700 mt-2 h-24">
              Un gran ambiente para sus negocios.
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault()
                  setModalProps({ showModal: true, contentKey: "conference" })
                }}
                className="text-center mt-4 mx-auto h-8 w-32 py-2 px-4 bg-gray-100 text-gray-800 flex justify-between items-center rounded"
              >
                <FaCamera className="inline" /> Ver más
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}
