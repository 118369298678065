import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { FaEnvelope, FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa"

export const Footer = () => {
  const {
    site: {
      meta: { links },
    },
  } = useStaticQuery(graphql`
    query FooterQuery {
      site {
        meta: siteMetadata {
          links {
            contact
            facebook
            instagram
            twitter
          }
        }
      }
    }
  `)

  return (
    <footer className="bg-gray-800">
      <div className="container pt-12 pb-12 flex flex-wrap text-center lg:flex-row-reverse lg:justify-between lg:items-start">
        <ul className="w-full lg:w-auto text-white">
          <FooterIconLink
            href={links.facebook}
            icon={FaFacebookF}
            label="Facebook"
          />
          <FooterIconLink
            href={links.twitter}
            icon={FaTwitter}
            label="Twitter"
          />
          <FooterIconLink
            href={links.instagram}
            icon={FaInstagram}
            label="Instagram"
          />
          <FooterIconLink
            href={links.contact}
            icon={FaEnvelope}
            label="E-mail"
          />
        </ul>
        <div className="w-full lg:w-auto pt-6 lg:pt-0 text-white text-sm text-left">
          &copy; Internacional Gran Hotel {new Date().getFullYear()}
          <br />
          Avenida Intercomunal, El Tigre - Anzoátegui, Venezuela
          <br />
          Apartado Postal 626
          <br />
          Rif: J-08006560-3
          <br />
        </div>
      </div>
    </footer>
  )
}

const FooterIconLink = ({ href, label, icon: Icon }) => {
  const linkParams = { href }

  if (href.startsWith("http")) {
    linkParams.target = "_blank"
    linkParams.rel = "noreferrer noopener"
  }

  return (
    <li className="inline-block px-2">
      <a
        {...linkParams}
        className="inline-flex h-8 w-8 border border-white text-white rounded-full items-center justify-center transition-colors duration-200 hover:text-gray-500 hover:bg-white hover:border-white"
      >
        <span className="sr-only">{label}</span>
        <Icon className="w-3 h-3 fill-current" />
      </a>
    </li>
  )
}
