import React from "react"
import ImageGallery from "react-image-gallery"
import GalleryContainer from "./GalleryContainer"
import { FaTimesCircle } from "react-icons/fa"

const Gallery = (props) => (
  <GalleryContainer onClose={props.onClose}>
    <div className="relative">
      <div className="absolute close-gallery" onClick={props.onClose}>
        <FaTimesCircle />
      </div>
      <ImageGallery showThumbnails={false} items={props.images} />
    </div>
  </GalleryContainer>
)

export default Gallery
