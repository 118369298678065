import React from "react"
import ReactDOM from "react-dom"

class GalleryContainer extends React.Component {
  constructor(props) {
    super(props)
    this.escFunction = this.escFunction.bind(this)
    this.DomNode = document.getElementById("gallery-root")
    this.el = document.createElement("div")
    this.el.setAttribute("class", "hotel-gallery-container")
    this.overlay = document.createElement("div")
    this.overlay.setAttribute("class", "gallery-overlay")
    this.overlay.onclick = function () {
      props.onClose()
    }
    this.el.onclick = function (e) {
      const attr = e.target.attributes
      if (attr.class && attr.class.value === "hotel-gallery-container") {
        props.onClose()
      }
    }
  }

  escFunction(e) {
    if (e.keyCode === 27) {
      this.props.onClose()
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false)
    this.DomNode.appendChild(this.el)
    this.DomNode.appendChild(this.overlay)
    this.DomNode.setAttribute("class", "active-gallery")
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false)
    this.DomNode.removeChild(this.el)
    this.DomNode.removeChild(this.overlay)
    this.DomNode.setAttribute("class", "")
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el)
  }
}

export default GalleryContainer
