import React from "react"
import offerPicture from "../images/offers.png"
import offerPictureMobile from "../images/offers-mobile.png"

export const Offers = () => {
  return (
    <div className="offers container pt-12 pb-12">
      <div className="grid grid-rows-1 grid-flow-col gap-0 rounded-md overflow-hidden pl-6 background-hotel-red">
        <img
          className="offers--desktop"
          alt="Ofertas especiales"
          src={offerPicture}
        />
        <img
          className="offers--mobile"
          alt="Ofertas especiales"
          src={offerPictureMobile}
        />
      </div>
    </div>
  )
}
