import React from "react"
import "moment/locale/es-mx"
import { FaCalendarDay, FaEnvelope, FaArrowsAltH } from "react-icons/fa"
import DayPickerInput from "react-day-picker/DayPickerInput"
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment"
import { getIsTodayOrFutureDate, endDateValidate } from "../utils/dateUtils"

export const BookingStepOne = ({ onSubmit, setFormValues, formValues }) => {
  const { startDate, endDate, email } = formValues

  const handleSubmit = (e) => {
    e.preventDefault()
    if (startDate && endDate && email) {
      onSubmit()
    }
  }

  return (
    <form className="p-2 lg:p-6 mb-4">
      <div className="flex flex-row">
        <div className="pb-6 pr-2">
          <DayPickerInput
            onDayChange={(date) =>
              setFormValues({
                ...formValues,
                startDate: date,
                endDate: null,
              })
            }
            value={startDate}
            formatDate={formatDate}
            parseDate={parseDate}
            format="LL"
            placeholder="Check In"
            inputProps={{
              className:
                "shadow appearance-none border rounded w-full py-2 px-3 pl-8 text-gray-700 leading-tight focus:outline-none focus:shadow-outline",
            }}
            classNames={{
              container: "DayPickerInput w-full",
              overlay: "DayPickerInput-Overlay",
              overlayWrapper: "DayPickerInput-OverlayWrapper text-gray-500",
            }}
            dayPickerProps={{
              locale: "es-mx",
              localeUtils: MomentLocaleUtils,
              disabledDays: getIsTodayOrFutureDate,
            }}
          />
          <FaCalendarDay className="-mt-7 ml-2 text-gray-300" />
        </div>
        <FaArrowsAltH className="mt-2" />
        <div className="pb-6 pl-2">
          <DayPickerInput
            onDayChange={(date) =>
              setFormValues({ ...formValues, endDate: date })
            }
            formatDate={formatDate}
            parseDate={parseDate}
            format="LL"
            value={endDate}
            placeholder="Check Out"
            inputProps={{
              className:
                "shadow appearance-none border rounded w-full py-2 px-3 pl-8 text-gray-700 leading-tight focus:outline-none focus:shadow-outline",
            }}
            classNames={{
              container: "DayPickerInput w-full",
              overlay: "DayPickerInput-Overlay",
              overlayWrapper: "DayPickerInput-OverlayWrapper text-gray-500",
            }}
            dayPickerProps={{
              locale: "es-mx",
              localeUtils: MomentLocaleUtils,
              disabledDays: function (day) {
                return endDateValidate(day, startDate)
              },
            }}
          />
          <FaCalendarDay className="-mt-7 ml-2 text-gray-300" />
        </div>
      </div>
      <div className="pb-6">
        <input
          className="shadow appearance-none border rounded w-full py-2 px-3 pl-8 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="email"
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) =>
            setFormValues({ ...formValues, email: e.target.value })
          }
        />
        <FaEnvelope className="-mt-7 ml-2 text-gray-300" />
      </div>
      <button
        onClick={handleSubmit}
        className="w-full bg-primary hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
      >
        Reservar
      </button>
    </form>
  )
}
