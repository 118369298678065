import moment from "moment"

export const getIsTodayOrFutureDate = (day) => {
  const today = moment()
  const formattedCell = moment(day)
  const diff = today.diff(formattedCell, "minutes")
  const isCellToday =
    today.format("DD/MM/YYYY") === formattedCell.format("DD/MM/YYYY")
  if (!isCellToday && !diff.toString().includes("-")) {
    return true
  }
  return false
}

export const endDateValidate = (day, startDate) => {
  const isTodayOrFutureDate = getIsTodayOrFutureDate(day)
  const isBiggerThanStartDate = () => {
    const diff = moment(startDate).diff(moment(day), "minutes")
    return !diff.toString().includes("-")
  }
  if (!isTodayOrFutureDate && !isBiggerThanStartDate()) {
    return false
  }
  return true
}
