import React from "react"
import {
  FaCalendarDay,
  FaEnvelope,
  FaArrowsAltH,
  FaPhone,
  FaUser,
  FaBed,
  FaUserPlus,
} from "react-icons/fa"
import DayPickerInput from "react-day-picker/DayPickerInput"
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment"
import { getIsTodayOrFutureDate, endDateValidate } from "../utils/dateUtils"

export const BookingStepTwo = (props) => {
  const { formValues, setFormValues } = props

  const {
    startDate,
    endDate,
    name,
    email,
    phone,
    guests,
    rooms,
    comments,
  } = formValues

  return (
    <>
      <h2 className="font-bold mb-4">Confirma tu reservación</h2>
      <p className="text-gray-700">
        Completa la información y haz click en confirmar
      </p>
      <form className="mt-4">
        <div className="flex flex-row">
          <div className="pb-6 pr-2">
            <DayPickerInput
              formatDate={formatDate}
              parseDate={parseDate}
              onDayChange={(date) =>
                setFormValues({
                  ...formValues,
                  startDate: date,
                  endDate: null,
                })
              }
              value={startDate}
              format="LL"
              placeholder="Check In"
              inputProps={{
                className:
                  "shadow appearance-none border rounded w-full py-2 px-3 pl-8 text-gray-700 leading-tight focus:outline-none focus:shadow-outline",
              }}
              classNames={{
                container: "DayPickerInput w-full",
                overlay: "DayPickerInput-Overlay",
                overlayWrapper: "DayPickerInput-OverlayWrapper text-gray-500",
              }}
              dayPickerProps={{
                locale: "es-mx",
                localeUtils: MomentLocaleUtils,
                disabledDays: getIsTodayOrFutureDate,
              }}
            />
            <FaCalendarDay className="-mt-7 ml-2 text-gray-300" />
          </div>
          <FaArrowsAltH className="mt-2" />
          <div className="pb-6 pl-2">
            <DayPickerInput
              formatDate={formatDate}
              parseDate={parseDate}
              onDayChange={(date) =>
                setFormValues({ ...formValues, endDate: date })
              }
              format="LL"
              value={endDate}
              placeholder="Check Out"
              inputProps={{
                className:
                  "shadow appearance-none border rounded w-full py-2 px-3 pl-8 text-gray-700 leading-tight focus:outline-none focus:shadow-outline",
              }}
              classNames={{
                container: "DayPickerInput w-full",
                overlay: "DayPickerInput-Overlay",
                overlayWrapper: "DayPickerInput-OverlayWrapper text-gray-500",
              }}
              dayPickerProps={{
                locale: "es-mx",
                localeUtils: MomentLocaleUtils,
                disabledDays: function (day) {
                  return endDateValidate(day, startDate)
                },
              }}
            />
            <FaCalendarDay className="-mt-7 ml-2 text-gray-300" />
          </div>
        </div>
        <div className="pb-6">
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 pl-8 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="email"
            type="email"
            value={email}
            onChange={(e) =>
              setFormValues({ ...formValues, email: e.target.value })
            }
            placeholder="Email"
          />
          <FaEnvelope className="-mt-7 ml-2 text-gray-300" />
        </div>
        <div className="pb-6">
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 pl-8 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="name"
            type="text"
            value={name}
            onChange={(e) =>
              setFormValues({ ...formValues, name: e.target.value })
            }
            placeholder="Nombre"
          />
          <FaUser className="-mt-7 ml-2 text-gray-300" />
        </div>
        <div className="pb-6">
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 pl-8 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="telephone"
            type="tel"
            value={phone}
            onChange={(e) =>
              setFormValues({ ...formValues, phone: e.target.value })
            }
            placeholder="Teléfono"
          />
          <FaPhone className="-mt-7 ml-2 text-gray-300" />
        </div>
        <div className="pb-6">
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 pl-8 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="guests"
            type="number"
            min="1"
            value={guests}
            onChange={(e) =>
              setFormValues({ ...formValues, guests: e.target.value })
            }
            placeholder="Huéspedes"
          />
          <FaUserPlus className="-mt-7 ml-2 text-gray-300" />
        </div>
        <div className="pb-6">
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 pl-8 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="rooms"
            type="number"
            min="1"
            value={rooms}
            onChange={(e) =>
              setFormValues({ ...formValues, rooms: e.target.value })
            }
            placeholder="Habitaciones"
          />
          <FaBed className="-mt-7 ml-2 text-gray-300" />
        </div>
        <div className="pb-6">
          <textarea
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="comments"
            type="text"
            value={comments}
            onChange={(e) =>
              setFormValues({ ...formValues, comments: e.target.value })
            }
            placeholder="Comentarios adicionales"
          />
        </div>
      </form>
    </>
  )
}
