import React from "react"
import Gallery from "./Gallery"
import { useStaticQuery, graphql } from "gatsby"

import room1 from "../images/rooms1.jpg"
import room2 from "../images/rooms2.jpg"
import room3 from "../images/rooms3.jpg"

const images = [
  {
    original: room1,
  },
  {
    original: room2,
  },
  {
    original: room3,
  },
]

const asyncImportBase = async (base) => await import(`../images/${base}`)

const buildDynamicImports = async (data, type) => {
  const promises = data.edges.map((edge) => {
    const { base } = edge.node
    return asyncImportBase(`${type}/${base}`)
  })
  const results = await Promise.all(promises)
  return results.map((r) => ({
    original: r.default,
  }))
}

export const Rooms = () => {
  const data = useStaticQuery(graphql`
    query {
      multiple: allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)/" }
          absolutePath: { regex: "/multiple/" }
        }
      ) {
        edges {
          node {
            base
          }
        }
      }
      matrimonial: allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)/" }
          absolutePath: { regex: "/matrimonial/" }
        }
      ) {
        edges {
          node {
            base
          }
        }
      }
      suite: allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)/" }
          absolutePath: { regex: "/suite/" }
        }
      ) {
        edges {
          node {
            base
          }
        }
      }
    }
  `)

  const { multiple, matrimonial, suite } = data
  const [showSlider, setShowSlider] = React.useState(false)
  const [sliderProps, setSliderProps] = React.useState({})
  const openSlider = (type) => {
    const action = {
      MULTIPLE: async () => {
        const imgData = await buildDynamicImports(multiple, "multiple")
        setSliderProps({
          images: imgData,
        })
        setShowSlider(true)
      },
      MATRIMONIAL: async () => {
        const imgData = await buildDynamicImports(matrimonial, "matrimonial")
        setSliderProps({
          images: imgData,
        })
        setShowSlider(true)
      },
      SUITE: async () => {
        const imgData = await buildDynamicImports(suite, "suite")
        setSliderProps({
          images: imgData,
        })
        setShowSlider(true)
      },
    }
    action[type]()
  }

  return (
    <>
      {showSlider && (
        <Gallery {...sliderProps} onClose={() => setShowSlider(false)} />
      )}
      <h2 className="text-center leading-tight pt-24 pb-12 text-3xl font-semibold text-gray-800">
        Habitaciones
      </h2>
      <div className="container grid grid-rows-3 lg:grid-rows-1 grid-flow-col md:gap-12">
        <div
          onClick={() => {
            openSlider("MATRIMONIAL")
          }}
          className="room mt-6 md:mt-0 flex relative rounded-md overflow-hidden col-span-12 lg:col-span-4"
          style={{ background: "rgba(255, 40, 37, .5)" }}
        >
          <img
            alt="Matrimonial"
            src={room1}
            className="grayscale-1 blend-multiply gatsby-image-wrapper opacity-60 transition-all duration-400"
          />
          <h2
            className="p-6 text-2xl font-semibold absolute text-center w-full text-white"
            style={{ lineHeight: "100%" }}
          >
            Matrimonial
          </h2>
        </div>
        <div
          onClick={() => {
            openSlider("SUITE")
          }}
          className="room mt-6 md:mt-0 flex relative rounded-md overflow-hidden col-span-12 lg:col-span-4"
          style={{ background: "rgba(255, 40, 37, .5)" }}
        >
          <img
            alt="Suite y Suite VIP"
            src={room2}
            className="grayscale-1 blend-multiply gatsby-image-wrapper opacity-60 transition-all duration-400"
          />
          <h2
            className="p-6 text-2xl font-semibold absolute text-center w-full text-white"
            style={{ lineHeight: "100%" }}
          >
            Suite y Suite VIP
          </h2>
        </div>
        <div
          onClick={() => {
            openSlider("MULTIPLE")
          }}
          className="room mt-6 md:mt-0 flex relative rounded-md overflow-hidden col-span-12 lg:col-span-4"
          style={{ background: "rgba(255, 40, 37, .5)" }}
        >
          <img
            alt="Doble, Triple, Cuadruple"
            src={room3}
            className="grayscale-1 blend-multiply gatsby-image-wrapper opacity-60 transition-all duration-400"
          />
          <h2
            className="p-6 text-2xl font-semibold absolute text-center w-full text-white"
            style={{ lineHeight: "100%" }}
          >
            Doble, Triple, Cuadruple
          </h2>
        </div>
      </div>
    </>
  )
}
