import React from "react"
import adonisCover from "../images/adonis/adonis (3).jpg"
import rumbaCover from "../images/rumbacafe/rumba (4).jpg"

export const Restaurants = () => {
  return (
    <div style={{ background: "#fafafa" }}>
      <h2 className="text-3xl font-semibold text-gray-800 px-6 py-24 pb-12 mx-auto text-center">
        Restaurantes
      </h2>
      <section>
        <div className="max-w-5xl px-6 py-16 mx-auto">
          <div className="items-center md:flex md:space-x-6">
            <div className="md:w-1/2">
              <h3 className="text-2xl font-semibold text-gray-800">Adonis</h3>
              <p className="max-w-md mt-4 text-gray-600">
                Somos Restaurant y Pizzería, con los mejores platos nacionales e
                internacionales. Nuestras pizzas son tradición en la zona.
              </p>
              <a
                target="_blank"
                href="https://www.instagram.com/adoniseltigre/"
                className="block mt-8 text-indigo-700 underline"
              >
                Síguenos en Instagram
              </a>
            </div>
            <div className="mt-8 md:mt-0 md:w-1/2">
              <div className="flex items-center justify-center">
                <div className="max-w-md">
                  <img
                    className="object-cover object-center w-full rounded-md shadow"
                    style={{ height: "500px" }}
                    src={adonisCover}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="max-w-5xl px-6 py-16 mx-auto">
          <div className="items-center md:flex md:space-x-6">
            <div className="md:w-1/2">
              <div className="flex items-center justify-center">
                <div className="max-w-md">
                  <img
                    className="object-cover object-center w-full rounded-md shadow"
                    style={{ height: "500px" }}
                    src={rumbaCover}
                  />
                </div>
              </div>
            </div>

            <div className="mt-8 md:mt-0 md:w-1/2">
              <h3 className="text-2xl font-semibold text-gray-800">
                Rumba café
              </h3>
              <p className="max-w-md mt-4 text-gray-600">
                Un lugar para disfrutar de los mejores tragos, cocteles y
                cervezas. Acompaña tus tragos con ricos platos de nuestra cocina
                y disfruta del ambiente que rumba café tiene para ofrecer.
              </p>
              <a
                target="_blank"
                href="https://www.instagram.com/rumba_cafe/"
                className="block mt-8 text-indigo-700 underline"
              >
                Síguenos en Instagram
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
