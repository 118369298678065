import React from "react"
import waIcon from "../images/wa.png"

export const WhatsApp = () => {
  const [focused, setFocused] = React.useState(false)
  const [closed, setClosed] = React.useState(false)

  const handleImgClick = () => {
    if (!focused && window.innerWidth <= 768) {
      setFocused(true)
      setClosed(false)
    } else {
      window.open("https://wa.me/584121377062", "_blank")
    }
  }

  const handleTooltipClose = () => {
    setClosed(true)
    setFocused(false)
  }

  return (
    <div
      className={`wa-fixed wa-fixed__container ${
        focused ? "wa-fixed--focused" : ""
      }`}
    >
      {!closed && (
        <div className="wa-fixed__tooltip">
          <div className="wa-fixed__close" onClick={handleTooltipClose}>
            <span>x</span>
          </div>
          <span className="green-circle">●</span> Estamos disponibles en
          WhatsApp
        </div>
      )}
      <a onClick={handleImgClick}>
        <img alt="WhatsApp" className="wa-fixed__img" src={waIcon} />
      </a>
    </div>
  )
}
