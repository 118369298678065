import React from "react"
import ReactDOM from "react-dom"

class ModalContainer extends React.Component {
  constructor(props) {
    super(props)
    this.DomNode = document.getElementById("modal-root")
    this.el = document.createElement("div")
    this.el.setAttribute("class", "modal-portal-container")
  }

  escFunction = (e) => {
    if (e.keyCode === 27) {
      this.props.setShowModal(false)
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false)
    this.DomNode.appendChild(this.el)
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false)
    this.DomNode.removeChild(this.el)
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el)
  }
}

export default ModalContainer
